export default class AppConfig {

    static get FIREBASE_CONFIG() {
        return {
            apiKey: "AIzaSyCMo3H9ABS44TTxbkp_OUOXrkEgm8SnftI",
            authDomain: "kujakuja-dev.firebaseapp.com",
            databaseURL: "https://kujakuja-dev.firebaseio.com",
            projectId: "kujakuja-dev",
            storageBucket: "kujakuja-dev.appspot.com",
            messagingSenderId: "934508205959",
            appId: "1:934508205959:web:94b507eabcb52879"
        };
    }

}
